.videoDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

@media screen and (max-width: 685px) {
  iframe {
  width: 90vw;
  }
}

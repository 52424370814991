.App {
  background: #ECECEC;
  height: 100%;
  min-height: 100vh
}

.mainDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}


main {
  background-color: white;
  width: 70vw;
  margin-top: 10vh;
  margin-bottom: 10vh;
  border-radius: 3px;
  padding-bottom: 10px;
}

h2 {
  text-align: center;
  margin-top: 5vh;
  font-family: American Typewriter, serif;
  font-weight: normal;
  font-size: 3rem;
}

.pink {
  color: #D63A92;
}

.editingPic {
  width: auto;
}

@media screen and (max-width: 965px) {
  main {
    width: 90vw;
    margin-top: 10px;
  }
}
@media screen and (max-width: 735px) {
  main {
    width: 95vw;
    margin-top: 10px;
  }
}

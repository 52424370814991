.Nav {
  background: #FFF;
  height: 20vh;
}

.App-header {
  display: flex;
  flex-direction: column;
}

.header-logo {
  display: flex;
  justify-content: center;
}

h1 {
  font-family: American Typewriter, serif;
  font-weight: normal;
  font-size: 8rem;
  margin: 0;
}

.hilt {
  font-size: 6rem;
  font-family: sans-serif;
  position: relative;
  bottom: 10px;
}

a {
  margin: 10px;
  text-decoration: none;
  color: black;
  font-size: 20px;
}


a:hover {
  color: #D63A92;
}

a:active {
  color: #D63A92;
  text-decoration: underline;
}

@media screen and (max-width: 1020px) {
  .Nav {
    height: 15vh;
  }
  h1 {
    font-size: 6rem;
  }
  .hilt {
    font-size: 4rem;
  }
}

@media screen and (max-width: 777px) {
  .Nav {
    height: 12.5vh;
  }
  h1 {
    font-size: 4rem;
  }
  .hilt {
    font-size: 2rem;
  }
}

@media screen and (max-width: 530px) {
  .Nav {
    height: 8vh;
    margin-bottom: 10px;
  }
  h1 {
    font-size: 2rem;
  }
  .hilt {
    font-size: 1rem;
    bottom: 5px;
  }
  nav {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 500px) {
  .Nav {
    height: 20vh;
    margin-bottom: 10px;
  }
  nav {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  a {
    font-size: 26px
  }
}

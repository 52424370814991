
p {
  font-size: 20px;
  display: inline;
  margin: 10px;
  padding: 0;
  line-height: 1.6;
}

.pDiv {
    margin: 10px;
}

img {
  border-radius: 3px;
  height: 320px;
  display: inline;
  float: left;
  margin: 0 10px;
  padding: 0;
  width: 244px;
}

@media screen and (max-width: 530px) {
  .pDiv {
      display: flex;
      align-items: center;
      flex-direction: column;

  }
  img {
    display: block;
    float: none;
  }
}
